import { useSelector } from 'react-redux';

const hackList = {
  hideStageSidebar: ['25594'],
  hideAttendanceModeBadge: ['5846'],
};

const useAccountIdHack = (hackName) => {
  const accountId = useSelector((state) => {
    const events = state.entities?.events;

    if (events) {
      const [firstEvent] = Object.values(events);
      return firstEvent?.account?.id;
    }
    return null;
  });
  return hackList[hackName]?.includes(accountId);
};

export default useAccountIdHack;
