import HomeIcon from '@material-ui/icons/Home';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import ChatIcon from '@material-ui/icons/Chat';
import MapIcon from '@material-ui/icons/Map';
import StarIcon from '@material-ui/icons/Star';
import BusinessIcon from '@material-ui/icons/Business';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import CameraIcon from '@material-ui/icons/Camera';
import ArchiveIcon from '@material-ui/icons/Archive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HotelIcon from '@material-ui/icons/Hotel';
import CloudIcon from '@material-ui/icons/Cloud';
import ToysIcon from '@material-ui/icons/Toys';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ExploreIcon from '@material-ui/icons/Explore';
import TrainIcon from '@material-ui/icons/Train';
import ForumIcon from '@material-ui/icons/Forum';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PublicIcon from '@material-ui/icons/Public';
import GamepadIcon from '@material-ui/icons/Gamepad';
import PrintIcon from '@material-ui/icons/Print';
import FlagIcon from '@material-ui/icons/Flag';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import WatchIcon from '@material-ui/icons/Watch';
import SecurityIcon from '@material-ui/icons/Security';
import SdCardIcon from '@material-ui/icons/SdCard';
import WifiIcon from '@material-ui/icons/Wifi';
import PaletteIcon from '@material-ui/icons/Palette';
import WorkIcon from '@material-ui/icons/Work';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VideocamIcon from '@material-ui/icons/Videocam';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import TvIcon from '@material-ui/icons/Tv';
import UsbIcon from '@material-ui/icons/Usb';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MicIcon from '@material-ui/icons/Mic';
import WebIcon from '@material-ui/icons/Web';
import SettingsIcon from '@material-ui/icons/Settings';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import TwitterXIcon from '../assets/TwitterXIcon';
import MyProfile from '../pages/MyProfile';
import EventChat from '../pages/EventChat';
import EventSchedule from '../pages/EventSchedule';
import { getCustomAvailableModules, getCurrentParticipant, getLocale } from './getters';
import ContactMailAltIcon from '../assets/ContactMailAltIcon';
import Custom from '../pages/Custom';
import People from '../pages/People';
import Sponsors from '../pages/Sponsors';
import Surveys from '../pages/Surveys';
import Speakers from '../pages/Speakers';
import SocialWall from '../pages/SocialWall';
import Quizzes from '../pages/Quizzes';
import AttendeeLeads from '../pages/AttendeeLeads';
import QASessions from '../pages/QASessions';
import Settings from '../pages/Settings';
import { unreadCount } from './chat';
// HACK for Campari
import CampariAgendaIcon2 from '../assets/campari-icons/CampariAgendaIcon2';
import CampariContactsIcon from '../assets/campari-icons/CampariContactsIcon';
// import CampariDownloadIcon from '../assets/campari-icons/CampariDownloadIcon';
import CampariFranchiseIcon from '../assets/campari-icons/CampariFranchiseIcon';
import CampariHangerIcon from '../assets/campari-icons/CampariHangerIcon';
import CampariHomeIcon from '../assets/campari-icons/CampariHomeIcon';
import CampariProfileIcon from '../assets/campari-icons/CampariProfileIcon';
import CampariSocialMediaIcon from '../assets/campari-icons/CampariSocialMediaIcon';
import CampariPhotoIcon from '../assets/campari-icons/CampariPhotoIcon';
import CampariLoungeIcon from '../assets/campari-icons/CampariLoungeIcon';
import AbeilleIcon from '../assets/guerlain-icons/AbeilleIcon';

// HACK
export const hackedIcons = {
  '50b1': {
    AttendeeProfile: CampariProfileIcon,
    Home: CampariHomeIcon,
    Workshops: CampariAgendaIcon2,
    clock: CampariAgendaIcon2,
    Speakers: CampariContactsIcon,
    briefcase: CampariHangerIcon,
    'camera-iris': CampariFranchiseIcon,
    SocialWall: CampariSocialMediaIcon,
    video: CampariPhotoIcon,
    star: CampariLoungeIcon,
  },
  'worldwide-meeting-2025': {
    usb: AbeilleIcon,
    Speakers: ContactMailAltIcon,
  },
};

// HACK
export const hackedNames = {
  'worldwide-meeting-2025': {
    mainStage: 'TEASER',
  },
};

const customModulesIcons = {
  star: StarIcon,
  home: HomeIcon,
  map: MapIcon,
  walk: DirectionsWalkIcon,
  hotel: HotelIcon,
  city: LocationCityIcon,
  train: TrainIcon,
  forum: ForumIcon,
  archive: ArchiveIcon,
  'camera-iris': CameraIcon,
  'file-pdf-box': PictureAsPdfIcon,
  fan: ToysIcon,
  earth: PublicIcon,
  compass: ExploreIcon,
  coffee: LocalCafeIcon,
  cloud: CloudIcon,
  flag: FlagIcon,
  flash: FlashOnIcon,
  gamepad: GamepadIcon,
  gift: CardGiftcardIcon,
  security: SecurityIcon,
  sd: SdCardIcon,
  printer: PrintIcon,
  palette: PaletteIcon,
  twitter: TwitterXIcon,
  video: VideocamIcon,
  watch: WatchIcon,
  wifi: WifiIcon,
  briefcase: WorkIcon,
  'desktop-mac': DesktopMacIcon,
  'crosshairs-gps': GpsFixedIcon,
  clock: WatchLaterIcon,
  'clipboard-check': AssignmentTurnedInIcon,
  'gamepad-variant': VideogameAssetIcon,
  television: TvIcon,
  usb: UsbIcon,
  bullhorn: VolumeDownIcon,
  'volume-high': VolumeUpIcon,
  'help-circle-outline': HelpOutlineIcon,
};

export const getCustomSubpages = (modules, currentAttendee) => {
  const customAvailableModules = getCustomAvailableModules(modules, currentAttendee);
  const locale = getLocale();
  const customSubpages = {};
  customAvailableModules.forEach(({ id, name: names, customParams: { icon, content } }) => {
    const name = names[locale] || names.en || names.es || names.fr;
    customSubpages[name] = {
      id,
      name,
      Icon: customModulesIcons[icon] || customModulesIcons.forum,
      component: Custom,
      props: { content, name },
    };
  });
  return customSubpages;
};

export const isMobileButtonVisible = ({ module, ...ids }) => {
  const moduleParams = Object.keys(ids).filter((id) => (
    !['token', 'email', 'ssoToken'].includes(id) // exclude auto-login query params
  ));
  const isShow = (typeof ids === 'object' && moduleParams.length > 0);
  return !isShow;
};

export const buildCustomSubpage = (module, locale) => {
  const {
    id, name: names, order, type,
    customParams: { icon, content },
  } = module;
  const name = names[locale] || names.en || names.es || names.fr;
  return {
    id,
    name,
    order,
    type,
    Icon: customModulesIcons[icon] || customModulesIcons.forum,
    icon,
    component: Custom,
    props: { content, name },
  };
};

export default {
  mainStage: {
    name: 'mainStage',
    Icon: LiveTvIcon,
    component: '',
    props: {},
  },
  homelessMainStage: {
    name: 'mainStage',
    Icon: HomeIcon,
    component: '',
    props: {},
  },
  settings: {
    name: 'settings',
    Icon: SettingsIcon,
    component: Settings,
    props: {},
  },
  Home: {
    name: 'Home',
    Icon: HomeIcon,
    component: '',
    props: {},
  },
  AttendeeProfile: {
    name: 'AttendeeProfile',
    Icon: PersonIcon,
    component: MyProfile,
    props: {},
  },
  Workshops: {
    name: 'Workshops',
    Icon: EventNoteIcon,
    component: EventSchedule,
    props: {
      noPaddingDrawer: true,
    },
    showBadge: ({
      entities,
      entities: {
        businessConferenceMeetings: meetings,
      },
      meta: {
        meetingStatuses,
      },
    }) => {
      const currentParticipant = getCurrentParticipant(entities);
      return (
        !!meetings && Object.values(meetings).some(({
          status, host,
        }) => status === meetingStatuses?.requested && host.id === currentParticipant.id)
      );
    },
  },
  Attendees: {
    name: 'Attendees',
    Icon: PeopleIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: People,
  },
  Messages: {
    name: 'Messages',
    Icon: ChatIcon,
    component: EventChat,
    props: {},
    showBadge: ({
      chat: { openChannels },
      messages,
      lastReadMessages,
    }) => !!messages && openChannels.some(({
      topic,
    }) => !!unreadCount(
      messages, topic, lastReadMessages
    )),
  },
  Sponsors: {
    name: 'Sponsors',
    Icon: BusinessIcon,
    props: {},
    component: Sponsors,
  },
  Surveys: {
    name: 'Surveys',
    Icon: ListAltIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: Surveys,
  },
  Speakers: {
    name: 'Speakers',
    Icon: MicIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: Speakers,
  },
  SocialWall: {
    name: 'SocialWall',
    Icon: WebIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: SocialWall,
  },
  AttendeeLeads: {
    name: 'AttendeeLeads',
    Icon: ContactMailAltIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: AttendeeLeads,
  },
  Quizzes: {
    name: 'Quizzes',
    Icon: EmojiObjectsIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: Quizzes,
  },
  QASessions: {
    name: 'QASessions',
    Icon: ForumIcon,
    props: {
      noPaddingDrawer: true,
    },
    component: QASessions,
  },
};
