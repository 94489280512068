import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Button from 'eventtia-ui-components/lib/Button';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import callApi from '../../actions/callApi';
import CalendarAddOnIcon from '../../assets/CalendarAddOnIcon';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: 22,
  },
}));

const DownloadActivity = ({
  businessConferenceId, id, name, button, className, callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { eventUri } = useParams();

  const downloadICS = () => {
    const params = { eventUri, activityId: id };
    if (businessConferenceId) params.businessConferenceId = businessConferenceId;
    dispatchCallApi('activityICS', params).then((res) => {
      if (!res.error) {
        const { response: { content: data } } = res;
        const blob = new Blob([data], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name.trim()}.ics`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    });
  };

  const onClick = (event) => {
    event.stopPropagation();
    downloadICS();
  };

  return button ? (
    <Button
      variant="secondary"
      className={className}
      icon={<CalendarAddOnIcon />}
      onClick={onClick}
    >
      {t('actions.addToCalendar')}
    </Button>
  ) : (
    <IconButton
      className={clsx(classes.iconButton, className)}
      onClick={onClick}
    >
      <CalendarAddOnIcon
        color="inherit"
        className={classes.icon}
      />
    </IconButton>
  );
};

DownloadActivity.propTypes = {
  callApi: PropTypes.func.isRequired,
  businessConferenceId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  button: PropTypes.bool,
  className: PropTypes.string,
};

DownloadActivity.defaultProps = {
  button: false,
  businessConferenceId: undefined,
  className: undefined,
};

export default connect(null, { callApi })(DownloadActivity);
