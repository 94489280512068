import React, { useState, useRef, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'eventtia-ui-components/lib/Loader';
import Tabs from 'eventtia-ui-components/lib/Tabs';
import Message from 'eventtia-ui-components/lib/Message';
import Button from 'eventtia-ui-components/lib/Button';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import { useParams } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import PrintIcon from '@material-ui/icons/Print';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchInput from 'eventtia-ui-components/lib/SearchInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getCurrentAttendee,
  getCurrentParticipant, getModuleNames,
} from '../../helpers/getters';
import { baseMoment } from '../../helpers/dates';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import printAgenda from '../../helpers/printAgenda';
import Switch from '../../components/Switch';
import Schedule from '../../components/Schedule';
import SubpageTitle from '../../components/SubpageTitle';
import DayPicker from '../../components/DayPicker';
import ScheduleList from '../../components/ScheduleList';
import ScheduleListFilters from '../../components/ScheduleListFilters';
import EventScheduleShow from '../EventScheduleShow';
import TabletExitButton from '../../components/TabletExitButton';
import callApi from '../../actions/callApi';
import { setActiveMeeting, setActiveWorkshop, setSlotId } from '../../actions/app';
import getVisibleWorkshops, { getWorkshopName } from '../../helpers/workshops';
import getMeetingVisibility from '../../helpers/meetings';
import ScheduleNoActivities from '../../components/ScheduleNoActivities';
import { ATTENDANCE_MODE, MEETING_REQUEST_STATUSES } from '../../helpers/constants';
import useEventUriHack from '../../hooks/hacks/useEventUriHack';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    overflowY: 'auto',
    padding: theme.spacing(
      theme.subpageDrawer.paddings.desktop.vertical,
      theme.subpageDrawer.paddings.desktop.horizontal
    ),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        theme.subpageDrawer.paddings.mobile.vertical,
        theme.subpageDrawer.paddings.mobile.horizontal
      ),
    },
  },
  tabletRoot: {
    width: '100%',
  },
  contentWrapper: {
    width: '100%',
  },
  actionsContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  leftButtons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  viewButton: {
    marginRight: theme.spacing(-2),
    minWidth: 0,
    height: 32,
  },
  printButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(-1.5),
    color: theme.palette.primary.main,
  },
  infoMsg: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoTopMsg: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5000,
  },
  scheduleWrapper: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(4, 0, 2),
  },
  customTab: {
    minWidth: 120,
  },
  mobileCustomTab: {
    minWidth: 90,
  },
  categoryWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(4, 0, 2.5, 7.5),
    width: 'unset',
    float: 'right',
  },
  categorySelect: {
    '& > div': {
      padding: theme.spacing(1, 1.5),
    },
  },
  categoryMenu: {
    '& li': {
      width: '100%',
      display: 'flex',
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  switchLabel: {
    color: theme.palette.darkGrey.main,
    margin: theme.spacing(2, 0),
  },
}));

const ACTIVITY_TYPES = {
  activities: 'activities',
  meetings: 'meetings',
  other: 'other',
};

const { REQUESTED } = MEETING_REQUEST_STATUSES;

const getAvailableTabs = (workshops, visibleWorkshops, currentParticipant) => {
  const tabs = [];

  const nonSponsorWorkshops = visibleWorkshops.filter((wId) => !workshops[wId]?.sponsorId);
  const sponsorWorkshops = visibleWorkshops.filter((wId) => !!workshops[wId]?.sponsorId);

  if (nonSponsorWorkshops.length) tabs.push(ACTIVITY_TYPES.activities);
  if (currentParticipant) tabs.push(ACTIVITY_TYPES.meetings);
  if (sponsorWorkshops.length) tabs.push(ACTIVITY_TYPES.other);

  return tabs;
};

const { VIRTUAL, PHYSICAL, HYBRID } = ATTENDANCE_MODE;

const sortActivities = (
  workshops, meetings, slots, selfBlockedSlots, dispatchSetActiveWorkshop,
  dispatchSetActiveMeeting, updateSlot, workshopCategoryId, selectedTab, meetingStatuses,
  survey, canBlockTimeSlots, updatingParticipant, visibleWorkshops, currentAttendeeWorkshops,
  canSeeAgenda, dispathcSetSlotId, slotsBySlotId, meetingsRequestCounter,
  workshopDefinitions, locale
) => {
  const days = {};
  const enabledSurvey = survey?.enabled;

  if (selectedTab === ACTIVITY_TYPES.other) visibleWorkshops.forEach((workshopId) => {
    if (workshops[workshopId] && workshops[workshopId].sponsorId) {
      const workshop = workshops[workshopId];
      const { startDate, id, ...other } = workshop;
      const name = getWorkshopName(workshop, workshopDefinitions, locale);
      const day = baseMoment(startDate).format('YYYY-MM-DD');
      const activity = {
        ...other,
        name,
        startDate,
        id,
        key: `w-${id}`,
        type: 'workshop',
        onClick: () => dispatchSetActiveWorkshop(id),
        registered: currentAttendeeWorkshops.includes(id),
      };
      if (days[day]) days[day].push(activity);
      if (!days[day]) days[day] = [activity];
    }
  });

  if (selectedTab === ACTIVITY_TYPES.activities) visibleWorkshops.forEach((workshopId) => {
    if (workshops[workshopId] && !workshops[workshopId].sponsorId) {
      const workshop = workshops[workshopId];
      const { startDate, id, workshopCategories, ...other } = workshop;
      const name = getWorkshopName(workshop, workshopDefinitions, locale);
      const excludeByCategory = workshopCategoryId && !workshopCategories
        .some(({ id: wId }) => wId === workshopCategoryId);
      const day = baseMoment(startDate).format('YYYY-MM-DD');
      let activity;
      if (!excludeByCategory) activity = {
        ...other,
        name,
        startDate,
        workshopCategories,
        id,
        key: `w-${id}`,
        type: 'workshop',
        onClick: () => dispatchSetActiveWorkshop(id),
        registered: currentAttendeeWorkshops.includes(id),
      };
      if (days[day] && activity) days[day].push(activity);
      if (!days[day]) days[day] = activity ? [activity] : [];
    }
  });

  if (selectedTab === ACTIVITY_TYPES.meetings && canSeeAgenda) Object.keys(meetingsRequestCounter)
    .forEach((slotId) => {
      const { startDate, endDate, ...other } = slotsBySlotId[slotId];
      const day = baseMoment(startDate).format('YYYY-MM-DD');

      const activity = {
        ...other,
        startDate,
        endDate,
        type: 'request',
        slotId,
        key: `s-${slotId}`,
        onClick: () => dispathcSetSlotId(slotId),
      };
      if (days[day]) days[day].push(activity);
      else days[day] = [activity];
    });

  if (selectedTab === ACTIVITY_TYPES.meetings && canSeeAgenda) meetings.filter(
    ({ status }) => [meetingStatuses.requested, meetingStatuses.accepted].includes(status)
  ).forEach(({
    startDate,
    id,
    isVirtual,
    status,
    slotId,
    ...other
  }) => {
    const day = baseMoment(startDate).format('YYYY-MM-DD');
    let type;
    let pendingEvaluation;
    if (status === meetingStatuses.accepted) {
      pendingEvaluation = enabledSurvey && !survey?.answered?.includes(Number(id))
        && baseMoment().isSameOrAfter(startDate, 'second');
      type = pendingEvaluation ? 'pendingMeeting' : 'meeting';
    } else type = 'pendingMeeting';
    const activity = {
      ...other,
      attendanceMode: isVirtual ? VIRTUAL : PHYSICAL,
      pendingEvaluation,
      startDate,
      isVirtual,
      id,
      slotId,
      type,
      key: `m-${id}`,
      onClick: () => dispatchSetActiveMeeting(id),
    };
    if (days[day]) days[day].push(activity);
    else days[day] = [activity];
  });

  if (selectedTab === ACTIVITY_TYPES.meetings && canBlockTimeSlots) {
    const meetingSlots = meetings.filter(
      ({ status }) => [meetingStatuses.requested, meetingStatuses.accepted].includes(status)
    ).map(({ slotId }) => slotId);
    const blockedSlotsMap = {};
    selfBlockedSlots.forEach((slotEpoch) => {
      blockedSlotsMap[slotEpoch] = true;
    });

    Object.keys(slots).forEach((slotEpoch) => {
      const [startDate, endDate, slotId] = slots[slotEpoch];
      const day = baseMoment(startDate).format('YYYY-MM-DD');

      const skip = meetingSlots.includes(slotId) || meetingsRequestCounter[slotId];
      let activity;
      if (!skip) activity = {
        startDate,
        endDate,
        isBlockedSlot: !!blockedSlotsMap[slotEpoch],
        slotId,
        id: slotEpoch,
        key: slotEpoch,
        type: 'ownSlot',
        onClick: () => updateSlot(slotEpoch),
        disabled: updatingParticipant,
      };

      if (days[day] && activity) days[day].push(activity);
      if (!days[day] && activity) days[day] = [activity];
    });
  }

  Object.values(days).forEach((activities) => {
    activities.sort((a1, a2) => {
      const diff = baseMoment(a1.startDate).diff(a2.startDate);
      if (diff !== 0) return diff;
      const endDateDiff = baseMoment(a1.endDate).diff(a2.endDate);
      if (endDateDiff !== 0) return endDateDiff;
      return a1.name.localeCompare(a2.name);
    });
  });
  return days;
};

const EventSchedule = ({
  entities, workshops, meetings, meetingRequests, fetching,
  workshopCategories: rawWorkshopCategories,
  meetingStatuses, participants, participantTypes, surveys, attendees, attendeeTypeCustomFields,
  notes, callApi: dispatchCallApi, attendanceType,
  updatingParticipant, appSettings, workshopId, conferences, workshopDefinitions,
  setActiveWorkshop: dispatchSetActiveWorkshop, meetingId,
  setActiveMeeting: dispatchSetActiveMeeting, events, scrollToTop, slotId,
  setSlotId: dispathcSetSlotId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { attendeeTypes } = entities;
  const [activeType, setActiveType] = useState(0);
  const [showMeetingEvaluationsMsg, setShowMeetingEvaluationsMsg] = useState(true);
  const [showPendingMeetingMsg, setShowPendingMeetingMsg] = useState(true);
  const { eventUri, locale } = useParams();
  const isScheduleDefaultList = useEventUriHack('isScheduleDefaultList');
  const [listView, setListView] = useState(isScheduleDefaultList);
  const [workshopCategoryId, setWorkshopCategoryId] = useState('');
  const [day, setDay] = useState('');
  const [showMeetingSpace, setShowMeetingSpace] = useState(false);
  const [showNewProposedTimeError, setShowNewProposedTimeError] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [keyword, setKeyword] = useState();
  const [filters, setFilters] = useState();
  const [filterNonAttendanceModeProgram, setFilterNonAttendanceModeProgram] = useState(false);
  const [event] = Object.values(events);
  const { attendanceMode: eventAttendanceMode } = event;
  const searchRef = useRef(null);
  const currentParticipant = getCurrentParticipant(entities);
  const moduleTitle = getModuleNames(appSettings, 'Workshops');
  const toggleFilters = useCallback(() => setFiltersOpen(!filtersOpen),
    [filtersOpen, setFiltersOpen]);

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const search = () => {
    setKeyword(searchRef.current?.value);
  };

  const clearSearch = () => {
    searchRef.current.value = '';
    searchRef.current.blur();
    setKeyword();
  };

  const currentParticipantType = participantTypes[currentParticipant?.participantTypeId];
  const canBlockTimeSlots = currentParticipantType?.canBlockTimeSlots;
  const selfBlockedSlots = currentParticipant?.blockedSlots;
  const surveyId = currentParticipantType?.survey?.id;
  const agenda = currentParticipantType?.agenda || {};
  const survey = surveys[surveyId];
  const enabledSurvey = survey?.enabled;
  const businessConferenceId = currentParticipant?.businessConference?.id;

  const updateSlot = (slot) => {
    if (updatingParticipant) return;
    const blockedSlots = [...new Set(currentParticipant.blockedSlots)];
    const slotIndex = blockedSlots.indexOf(slot);
    if (slotIndex > -1) blockedSlots.splice(slotIndex, 1);
    else blockedSlots.push(slot);

    dispatchCallApi('updateParticipant', {
      eventUri,
      businessConferenceId,
      participantId: currentParticipant.id,
      blockedSlots,
    });
  };

  const { canSeeAgenda, canScheduleMeetings } = getMeetingVisibility(entities);
  const { visibleWorkshops, currentAttendeeWorkshops } = getVisibleWorkshops(entities, false);

  const currentAttendee = getCurrentAttendee(entities);
  const attendeeTypeId = currentAttendee?.attendeeType.id;
  const attendeeTypeAttendanceMode = attendeeTypes[attendeeTypeId]?.attendanceMode;

  let filteredVisibleWorkshops = visibleWorkshops;

  if (filterNonAttendanceModeProgram) filteredVisibleWorkshops = visibleWorkshops.filter((wId) => (
    (workshops[wId].attendanceMode === attendanceType || workshops[wId].attendanceMode === HYBRID)
  ));
  else if (attendeeTypeAttendanceMode !== HYBRID) filteredVisibleWorkshops = visibleWorkshops
    .filter((wId) => (
      workshops[wId].attendanceMode === attendeeTypeAttendanceMode
      || workshops[wId].attendanceMode === HYBRID
    ));

  const filteredMeetings = filterNonAttendanceModeProgram
    ? Object.values(meetings).filter((meeting) => (
      (attendanceType === 'online'
        ? (
          !!meeting.isVirtual
          || !!meeting.hostAttendanceMode
          || !!meeting.participantAttendanceMode)
        : (
          !meeting.hostAttendanceMode
          && !meeting.participantAttendanceMode
          && !meeting.isVirtual
        )
      )
    )) : Object.values(meetings);

  const activityTypes = getAvailableTabs(workshops, visibleWorkshops, currentParticipant);
  const onChangeType = (index) => {
    if (activityTypes[index] !== ACTIVITY_TYPES.activities) setWorkshopCategoryId('');
    setActiveType(index);
    setDay('');
  };
  const items = activityTypes.map((type, index) => ({
    name: t(`schedule:activityType.${type}`),
    onClick: () => onChangeType(index),
  }));

  const slotsBySlotId = {};
  Object.values(agenda).forEach(([startDate, endDate, id]) => {
    slotsBySlotId[id] = { startDate, endDate };
  });

  const meetingsRequestCounter = {};

  Object.values(meetingRequests).filter(({ status }) => status === REQUESTED).forEach((request) => {
    if (!meetingsRequestCounter[request.slotId]) meetingsRequestCounter[request.slotId] = [];
    meetingsRequestCounter[request.slotId].push(request.id);
  });

  Object.values(meetings).forEach(({ slotId: meetingSlotId }) => {
    if (meetingsRequestCounter[meetingSlotId]) delete meetingsRequestCounter[meetingSlotId];
  });

  const activitiesByDay = sortActivities(
    workshops, filteredMeetings, agenda, selfBlockedSlots,
    dispatchSetActiveWorkshop, dispatchSetActiveMeeting, updateSlot,
    workshopCategoryId, activityTypes[activeType], meetingStatuses, survey, canBlockTimeSlots,
    updatingParticipant, filteredVisibleWorkshops, currentAttendeeWorkshops, canSeeAgenda,
    dispathcSetSlotId, slotsBySlotId, meetingsRequestCounter, workshopDefinitions, locale
  );
  const days = Object.keys(activitiesByDay);

  const workshopCategories = Object.values(rawWorkshopCategories)
    .map(({ id, name }) => ({ label: name, value: id }));

  const activities = activitiesByDay[day];

  const pendingMeetingEvaluations = enabledSurvey && Object.values(meetings).some(
    ({ id: meetingIds, startDate, status }) => (
      !survey?.answered?.includes(Number(meetingIds))
      && baseMoment().isSameOrAfter(startDate, 'second')
      && status === meetingStatuses.accepted
    )
  );

  const bookingDeadlineDate = conferences?.[businessConferenceId]?.bookingDeadlineDate;
  const multipleRequestsEnabled = conferences?.[businessConferenceId]?.multipleRequestsEnabled;
  const scheduleFinished = baseMoment().isAfter(bookingDeadlineDate);
  const pendingMeetings = !scheduleFinished && Object.values(meetings).some(({
    status, host, endDate,
  }) => (
    status === meetingStatuses.requested
    && host.id === currentParticipant.id
    && baseMoment().isBefore(endDate)
  ));

  const printableMeetings = Object.values(meetings).filter(
    ({ status }) => [meetingStatuses.requested, meetingStatuses.accepted].includes(status)
  );

  const areThereMeetings = Object.values(meetings).some(
    ({ status }) => [meetingStatuses.requested, meetingStatuses.accepted].includes(status)
  );

  const printMeetings = () => printAgenda(
    printableMeetings, eventUri, participants, meetingStatuses, notes, events, appSettings
  );

  const meetingTabWithoutMeetings = !areThereMeetings && !pendingMeetingEvaluations;

  if (fetching) return (
    <div className={clsx(classes.root, tablet && classes.tabletRoot)}>
      <Loader loading variant="absolute" />
    </div>
  );
  const noWorkshops = Object.keys(workshops).length === 0
    && [ACTIVITY_TYPES.activities, ACTIVITY_TYPES.other].includes(activityTypes[activeType]);
  // const noActivities = !activities?.length && typeall;
  const noMeetings = meetingTabWithoutMeetings && !showMeetingSpace
    && selfBlockedSlots?.length === 0 && activityTypes[activeType] === ACTIVITY_TYPES.meetings;
  const noRequests = Object.keys(meetingsRequestCounter).length === 0
    && activityTypes[activeType] === ACTIVITY_TYPES.meetings;

  const workshop = !!Number(workshopId) && workshops[workshopId];

  const meeting = !!Number(meetingId) && meetings[meetingId];

  let showContentProps;
  if (workshop) showContentProps = {
    type: 'workshop',
    workshop,
    scrollToTop,
  };
  else if (meeting) showContentProps = {
    type: 'meeting',
    meeting,
    setShowNewProposedTimeError,
    scrollToTop,
    currentParticipant,
    attendees,
    attendeeTypeCustomFields,
  };
  else if (slotId) showContentProps = {
    type: 'requests',
    slotId,
    attendees,
    attendeeTypeCustomFields,
    setShowNewProposedTimeError,
  };

  const showAttendanceModeFilter = (activityTypes[activeType] !== ACTIVITY_TYPES.other)
  && (eventAttendanceMode === HYBRID && [VIRTUAL, PHYSICAL].includes(attendanceType));

  return (
    <>
      {showContentProps && (
        <EventScheduleShow
          {...showContentProps}
        />
      )}
      <Container className={clsx(classes.root, tablet && classes.tabletRoot)}>
        {!workshopId && !meetingId && (
          <TabletExitButton />
        )}
        <SubpageTitle text={moduleTitle} />
        <div className={classes.contentWrapper}>
          <div className={classes.actionsContainer}>
            <DayPicker
              options={days}
              day={day}
              onChange={setDay}
              allowAll={!!listView}
            />
            {!!listView && (
              <div className={classes.search}>
                {mobile ? (
                  <IconButton
                    aria-label="filter"
                    className={classes.filterSmallButton}
                    onClick={toggleFilters}
                    color="primary"
                    size="small"
                  >
                    <FilterListIcon />
                  </IconButton>
                ) : (
                  <Button
                    icon={<FilterListIcon />}
                    variant="tertiary"
                    onClick={toggleFilters}
                    small
                  >
                    {filtersOpen ? t('global:actions.hideFilters') : t('global:filters.title')}
                  </Button>
                )}
                <SearchInput
                  placeholder={t('global:forms.searchKeyword')}
                  inputRef={searchRef}
                  onSubmit={search}
                  onClear={clearSearch}
                  className={mobile ? classes.mobileSearchInput : undefined}
                  fullWidth={false}
                  margin="dense"
                />
              </div>
            )}
            <div className={classes.leftButtons}>
              <Button
                className={classes.viewButton}
                icon={!listView ? <ListIcon /> : <ViewDayIcon />}
                variant="tertiary"
                onClick={() => { setListView(!listView); setKeyword(); }}
                small
              >
                {!listView ? t('schedule:activities.listView') : t('schedule:activities.dayView')}
              </Button>
              {!!printableMeetings?.length && (
                <IconButton className={classes.printButton} onClick={printMeetings}>
                  <PrintIcon />
                </IconButton>
              )}
            </div>
          </div>
          {showNewProposedTimeError && (
            <Message
              className={classes.infoTopMsg}
              onClose={() => setShowNewProposedTimeError(false)}
              type="error"
              title={t('schedule:title.error')}
              message={t('schedule:info.noAvailableSlot')}
            />
          )}
          {pendingMeetings && showPendingMeetingMsg && (
            <Message
              className={classes.infoMsg}
              onClose={() => setShowPendingMeetingMsg(false)}
              type="warning"
              title={t('schedule:title.reminder')}
              message={t('schedule:info.pendingMeetings')}
            />
          )}
          {pendingMeetingEvaluations && showMeetingEvaluationsMsg && (
            <Message
              className={classes.infoMsg}
              onClose={() => setShowMeetingEvaluationsMsg(false)}
              type="warning"
              title={t('schedule:title.reminder')}
              message={t('schedule:info.pendingMeetingEvaluations')}
            />
          )}
          {listView && filtersOpen && (
            <ScheduleListFilters filters={filters} setFilters={setFilters} />
          )}
          <Tabs
            variant="filter"
            activeItem={activeType}
            items={items}
            tabProps={{ className: mobile ? classes.mobileCustomTab : classes.customTab }}
          />
          {showAttendanceModeFilter && (
            <FormControlLabel
              className={classes.switchLabel}
              value="start"
              control={(
                <Switch
                  color="secondary"
                  onChange={() => setFilterNonAttendanceModeProgram(
                    !filterNonAttendanceModeProgram
                  )}
                  checked={filterNonAttendanceModeProgram}
                />
              )}
              label={t(`schedule:activityType.${attendanceType === VIRTUAL
                ? 'onlyViewVirtualActivity' : 'onlyViewInPersonActivity'}`, {
                activityType: t(`schedule:activityType.activityTypesFilter.${activityTypes[activeType]}`),
              })}
              labelPlacement="start"
            />
          )}
          {!listView ? (
            <>
              <div
                className={classes.categoryWrapper}
              >
                {workshopCategories.length > 0
                  && activityTypes[activeType] === ACTIVITY_TYPES.activities && (
                    <SelectInput
                      id="workshop-category"
                      size="small"
                      className={classes.categorySelect}
                      options={workshopCategories}
                      handleChange={setWorkshopCategoryId}
                      value={workshopCategoryId}
                      emptyValueLabel={t('schedule:activities.viewAllActivities')}
                      MenuProps={{ className: classes.categoryMenu }}
                    />
                )}
              </div>
              <div className={classes.scheduleWrapper}>
                {(!multipleRequestsEnabled && noMeetings)
                || (multipleRequestsEnabled && (noMeetings && noRequests))
                || noWorkshops ? (
                  <ScheduleNoActivities
                    activeType={activityTypes[activeType]}
                    setShowMeetingSpace={setShowMeetingSpace}
                    canBlockTimeSlots={canBlockTimeSlots}
                    canScheduleMeetings={canScheduleMeetings}
                  />
                  ) : (
                    <Schedule
                      activities={activities}
                    />
                  )}
              </div>
            </>
          ) : (
            <ScheduleList
              activeType={activityTypes[activeType]}
              activities={activitiesByDay}
              day={day}
              keyword={keyword}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </div>
      </Container>
    </>
  );
};

EventSchedule.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  workshopId: PropTypes.string,
  setActiveMeeting: PropTypes.func.isRequired,
  setSlotId: PropTypes.func.isRequired,
  meetingId: PropTypes.string,
  slotId: PropTypes.string,
  entities: CustomPropTypes.entities.isRequired,
  fetching: PropTypes.bool.isRequired,
  workshopCategories: PropTypes.objectOf(
    CustomPropTypes.workshopCategory
  ),
  workshops: PropTypes.objectOf(
    CustomPropTypes.workshop
  ),
  workshopDefinitions: PropTypes.objectOf(
    CustomPropTypes.workshopDefinition
  ),
  meetings: PropTypes.objectOf(
    CustomPropTypes.meeting
  ),
  meetingRequests: PropTypes.objectOf(CustomPropTypes.meetingRequest),
  participants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  participantTypes: CustomPropTypes.participantType,
  surveys: PropTypes.objectOf(
    CustomPropTypes.survey
  ),
  meetingStatuses: PropTypes.objectOf(
    PropTypes.number
  ),
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  notes: PropTypes.objectOf(
    CustomPropTypes.note
  ),
  setActiveWorkshop: PropTypes.func.isRequired,
  callApi: PropTypes.func.isRequired,
  updatingParticipant: PropTypes.bool.isRequired,
  appSettings: PropTypes.objectOf(
    CustomPropTypes.appSettings.isRequired
  ).isRequired,
  attendanceType: PropTypes.oneOf([PHYSICAL, VIRTUAL]),
  conferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ),
  scrollToTop: PropTypes.func.isRequired,
};

EventSchedule.defaultProps = {
  workshopId: null,
  meetingId: null,
  slotId: null,
  workshopCategories: {},
  workshops: {},
  workshopDefinitions: {},
  meetings: {},
  meetingRequests: {},
  surveys: {},
  participants: {},
  participantTypes: {},
  meetingStatuses: {},
  attendees: {},
  attendeeTypeCustomFields: {},
  notes: {},
  attendanceType: '',
  conferences: {},
};

const mapStateToProps = ({
  app: {
    attendanceType,
    activeSubpage: {
      workshopId,
      meetingId,
      slotId,
    },
  },
  entities,
  entities: {
    events,
    surveys,
    workshops,
    workshopDefinitions,
    workshopCategories,
    businessConferences,
    businessConferenceParticipantTypes,
    businessConferenceMeetings: meetings,
    meetingRequests,
    attendees,
    attendeeTypeCustomFields,
    businessConferenceParticipants: participants,
    notes,
    appSettings,
  },
  fetchStatus: {
    workshops: {
      isFetching: fetchingWorkshops,
    },
    meetings: {
      isFetching: fetchingMeetings,
    },
    speakers: {
      isFetching: isFetchingSpeakers,
    },
    updateParticipant: {
      isFetching: updatingParticipant,
    },
  },
  meta: {
    meetingStatuses,
  },
}) => ({
  events,
  meetingId,
  slotId,
  attendanceType,
  surveys,
  workshopId,
  entities,
  workshops,
  workshopDefinitions,
  workshopCategories,
  meetings,
  meetingRequests,
  notes,
  participants,
  conferences: businessConferences,
  participantTypes: businessConferenceParticipantTypes,
  fetching: fetchingWorkshops || fetchingMeetings || isFetchingSpeakers,
  meetingStatuses,
  attendees,
  attendeeTypeCustomFields,
  updatingParticipant,
  appSettings,
});

export default connect(
  mapStateToProps, { callApi, setActiveWorkshop, setActiveMeeting, setSlotId }
)(EventSchedule);
