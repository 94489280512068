import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { baseMoment } from '../../helpers/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    minWidth: 240,
  },
  mobileRoot: {
    flex: 1,
    width: '100%',
    margin: theme.spacing(0, 0, 2, 0),
  },
  select: {
    textTransform: 'capitalize',
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.darkGrey.main,
      borderBottomWidth: 1,
    },
    '&:after': {
      display: 'none !important',
    },
  },
  icon: {
    color: theme.palette.primary.main,
    transform: 'none',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
}));

export const formatDay = (date, locale) => {
  let dateFormat;
  switch (locale) {
    case 'pt':
      dateFormat = 'dddd, Do [de] MMMM';
      break;
    case 'it':
      dateFormat = 'dddd, D MMMM';
      break;
    case 'ja':
      dateFormat = 'AAAA MMMM DDDD';
      break;
    case 'zh':
      dateFormat = 'AAAA MMMM DDDD';
      break;
    case 'en':
      dateFormat = 'dddd, MMMM Do';
      break;
    default:
      dateFormat = 'dddd, MMMM D';
  }
  return baseMoment(date).format(dateFormat);
};

const DayPicker = ({ onChange, options, day, allowAll }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { locale } = useParams();
  const { t } = useTranslation('schedule');

  useEffect(() => {
    // eslint-disable-next-line curly
    if (!day && options.length > 0) {
      // set default date
      if (allowAll && typeof day === 'undefined') onChange('');
      else if (!allowAll) {
        const now = baseMoment();
        let defaultDate;
        let currentDay;
        let previousDay;
        let firstNextDay;
        options.forEach((date) => {
          if (baseMoment(date).isSame(now, 'day')) currentDay = date;
          else if (!previousDay) previousDay = date;
          else if (baseMoment(date).isAfter(previousDay)
            && baseMoment(date).isBefore(now)) previousDay = date;
          else if (!firstNextDay) firstNextDay = date;
          else if (baseMoment(date).isBefore(firstNextDay)
            && baseMoment(date).isAfter(now)) firstNextDay = date;
        });
        if (currentDay) defaultDate = currentDay;
        else if (previousDay) defaultDate = previousDay;
        else defaultDate = firstNextDay;

        onChange(defaultDate);
      }
    }
  }, [day, allowAll, onChange, options]);

  if (options.length === 0) return null;

  return (
    <div className={clsx(classes.root, mobile && classes.mobileRoot)}>
      <FormControl fullWidth>
        <Select
          value={day}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          className={classes.select}
          classes={{
            icon: classes.icon,
            iconOpen: classes.icon,
          }}
          IconComponent={DateRangeIcon}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          }}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) return t('activities.allDates');

            return formatDay(selected, locale);
          }}
        >
          {!!allowAll && (
            <MenuItem
              value=""
              className={classes.menuItem}
            >
              {t('activities.allDates')}
            </MenuItem>
          )}
          {options.sort().map((date) => (
            <MenuItem
              value={date}
              key={date}
              className={classes.menuItem}
            >
              {formatDay(date, locale)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

DayPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  day: PropTypes.string,
  allowAll: PropTypes.bool,
};

DayPicker.defaultProps = {
  day: '',
  allowAll: false,
};

export default DayPicker;
