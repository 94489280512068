import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import DevicesIcon from '@material-ui/icons/Devices';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const { VIRTUAL, PHYSICAL, HYBRID } = ATTENDANCE_MODE;

const useStyles = makeStyles((theme) => ({
  location: {
    display: 'inline-flex',
    fontWeight: 'normal',
  },
  locationText: {
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ActivityLocation = ({
  activity,
  className,
  multiline,
}) => {
  const { t } = useTranslation('schedule');
  const classes = useStyles();

  const {
    location,
    attendanceMode,
  } = activity;

  const workshopLocation = useMemo(() => {
    switch (attendanceMode) {
      case VIRTUAL:
        return t('events:type.online');
      case PHYSICAL:
        return location || t('activities.onsite');
      case HYBRID:
      default:
        return location ? `${location} / ${t('events:type.online')}`
          : `${t('events:type.offline')} / ${t('events:type.online')}`;
    }
  }, [attendanceMode, location]);

  const LocationIcon = useMemo(() => {
    switch (attendanceMode) {
      case VIRTUAL:
        return DevicesIcon;
      case PHYSICAL:
      case HYBRID:
      default:
        return PlaceIcon;
    }
  }, [attendanceMode]);

  return (
    <div className={clsx(classes.location, !multiline && classes.ellipsis, className)}>
      <LocationIcon color="primary" className={classes.icon} />
      <div className={clsx(classes.locationText, !multiline && classes.ellipsis)}>
        {workshopLocation}
      </div>
    </div>
  );
};

ActivityLocation.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.shape({
    location: PropTypes.string,
    attendanceMode: PropTypes.string,
  }).isRequired,
  multiline: PropTypes.bool,
};

ActivityLocation.defaultProps = {
  className: undefined,
  multiline: false,
};

export default ActivityLocation;
