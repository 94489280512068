import humps from 'humps';
import { getLocale } from './getters';

const endpoints = {
  login: ({ eventUri }) => `events/${eventUri}/app-auth`,
  accessToken: ({ eventUri }) => `events/${eventUri}/app-access-token`,
  ssoAuth: () => 'app-sso-auth',
  attendeeTypes: ({ eventUri }) => `events/${eventUri}/attendee_types`,
  attendees: ({
    eventUri, attendeeTypes, sort, offsetToUtc, showOnlyConfirmed,
  }) => `events/${eventUri}/attendees?order=${sort || 'first_name'}${attendeeTypes ? `&attendee_types=${attendeeTypes}` : ''}&include=business_conference_participants,business_conference_participants.participant_type${showOnlyConfirmed ? '&only_confirmed=true' : ''}${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  singleAttendee: ({ eventUri, id, offsetToUtc }) => `events/${eventUri}/attendees/${id}?include=attendee_type_custom_fields,attendee_type,business_conference_participants,business_conference_participants.participant_type${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  singleeAttendeeSimple: ({ eventUri, id }) => `events/${eventUri}/attendees/${id}?include=attendee_type`,
  currentAttendee: ({ eventUri, id, offsetToUtc }) => `events/${eventUri}/attendees/${id}?include=attendee_type_custom_fields${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  // this prevents LoggedInLayout from reloading the whole app
  refetchCurrentAttendee: ({ eventUri, id, offsetToUtc }) => `events/${eventUri}/attendees/${id}?include=attendee_type_custom_fields${offsetToUtc ? '&offset_to_utc=true' : ''}`,
  updateCurrentAttendee: ({ eventUri, id }) => `events/${eventUri}/attendees/${id}?include=attendee_type_custom_fields&offset_to_utc=true`,
  settings: ({ eventUri }) => `events/${eventUri}/app_settings`,
  manifest: ({ eventUri }) => `events/${eventUri}/app_settings/manifest`,
  meeting: ({ eventUri, businessConferenceId, meetingId, offsetToUtc }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  meetings: ({ eventUri, businessConferenceId, participantId, offsetToUtc }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/agenda?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  meetingCheckIn: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/mark-attended`,
  changeMeetingAttendanceMode: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/change-attendance-mode`,
  participantTypes: ({ eventUri, businessConferenceId, offsetToUtc }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participant_types?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  requestMeeting: ({ eventUri, businessConferenceId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings`,
  meetingsRequests: ({ eventUri, businessConferenceId }) => `/events/${eventUri}/business_conferences/${businessConferenceId}/meeting_requests?include=sent_by.attendee,received_by.attendee`,
  createMeetingRequest: ({ eventUri, businessConferenceId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/meeting_requests`,
  approveMeetingRequest: ({ eventUri, businessConferenceId, meetingRequestId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/meeting_requests/${meetingRequestId}/approve`,
  rejectMeetingRequest: ({ eventUri, businessConferenceId, meetingRequestId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/meeting_requests/${meetingRequestId}`,
  surveys: ({ eventUri }) => `events/${eventUri}/surveys?include=custom_fields`,
  quizzes: ({ eventUri }) => `events/${eventUri}/surveys?include=custom_fields,event_files&session_type=quiz`,
  activeQuizQuestion: ({ eventUri, id }) => `events/${eventUri}/surveys/${id}/active_question`,
  singleSurvey: ({ eventUri, id }) => `events/${eventUri}/surveys/${id}?include=custom_fields`,
  addSurveyAnswer: ({ eventUri, id }) => `events/${eventUri}/surveys/${id}/answers`,
  participant: ({
    eventUri, businessConferenceId, participantId, offsetToUtc,
  }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}?${offsetToUtc ? 'offset_to_utc=true' : ''}`,
  updateParticipant: ({
    eventUri, businessConferenceId, participantId,
  }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}`,
  countries: 'countries',
  regions: ({ countryId }) => `countries/${countryId}/regions`,
  cities: ({ countryId, regionId }) => `countries/${countryId}/regions/${regionId}/cities`,
  sponsors: ({ eventUri }) => `events/${eventUri}/sponsors?include_file_category=true&include_representatives=true&attendance_mode=0,1&include=category,attendee,attendee.attendee_type_custom_fields,sponsor_representatives.attendee,sponsor_representatives.attendee.attendee_type,sponsor_representatives.attendee.attendee_type_custom_fields,attendee.attendee_type`,
  sponsorsFiles: ({ eventUri, fileCategoryId, id }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files?sponsor_id=${id}`,
  hotspots: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/hotspots`,
  sponsorProducts: ({ eventUri, sponsorId }) => `events/${eventUri}/sponsors/${sponsorId}/sponsor_products`,
  markAsDownloaded: ({ eventUri, fileCategoryId, fileId }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files/${fileId}/mark_as_downloaded`,
  sponsorActivities: ({ eventUri, sponsorId }) => `events/${eventUri}/workshops?offset_to_utc=true${sponsorId ? `&sponsor_id=${sponsorId}` : ''}`,
  workshops: ({ eventUri, attendeeTypeId }) => `events/${eventUri}/workshops?include=workshop_categories,category&offset_to_utc=true&include_sponsor_activities=true&include_file_category=true${attendeeTypeId ? `&attendee_type_id=${attendeeTypeId}` : ''}`,
  workshopDefinitions: ({ eventUri }) => `events/${eventUri}/workshop_definitions`,
  attendeeWorkshops: ({ eventUri }) => `events/${eventUri}/attendee_workshops`,
  registerAttendee: ({ eventUri }) => `events/${eventUri}/attendee_workshops`,
  unregisterAttendee: ({ eventUri }) => `events/${eventUri}/attendee_workshops`,
  workshopsFiles: ({ eventUri, fileCategoryId, id }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files?workshop_id=${id}`,
  speakers: ({ eventUri }) => `events/${eventUri}/speakers?include_file_category=true`,
  speakersFiles: ({ eventUri, fileCategoryId, id }) => `events/${eventUri}/event_file_categories/${fileCategoryId}/event_files?speaker_id=${id}`,
  updateNote: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/notes`,
  updateMeetingDuration: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/durations`,
  updateMeeting: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}`,
  rateParticipant: ({ eventUri, businessConferenceId, participantId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/rating`,
  resetRating: ({ eventUri, businessConferenceId, participantId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/rating`,
  denyParticipant: ({ eventUri, businessConferenceId, participantId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/blacklist`,
  allowParticipant: ({ eventUri, businessConferenceId, participantId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${participantId}/blacklist`,
  denyList: ({ eventUri, businessConferenceId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/blacklist`,
  ratings: ({ eventUri, businessConferenceId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/ratings`,
  recommendedParticipants: ({
    eventUri, businessConferenceId, currentParticipantId,
  }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_participants/${currentParticipantId}/recommendations`,
  // social wall
  singlePost: ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}`,
  createSocialPost: ({ eventUri }) => `events/${eventUri}/social_posts`,
  updateSocialPost: ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}`,
  deleteSocialPost: ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}`,
  socialPosts: ({ eventUri }) => `events/${eventUri}/social_posts`,
  createPostComment: ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}/post_comments`,
  postComments: ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}/post_comments`,
  // updatePostComment:
  // ({ eventUri, postId }) => `events/${eventUri}/social_posts/${postId}/post_comments`,
  deletePostComment: ({ eventUri, postId, commentId }) => `events/${eventUri}/social_posts/${postId}/post_comments/${commentId}`,
  // attendee leads:
  attendeeLeads: ({ eventUri }) => `events/${eventUri}/attendee_leads`,
  createOrUpdateAttendeeLead: ({ eventUri, uuid }) => `events/${eventUri}/attendee_leads/${uuid}`,
  deleteAttendeeLead: ({ eventUri, uuid }) => `events/${eventUri}/attendee_leads/${uuid}`,
  batchUpdateAttendeeLeads: ({ eventUri }) => `events/${eventUri}/attendee_leads/batch`,
  downloadAttendeeLeads: ({ eventUri }) => `events/${eventUri}/attendee_leads/report`,
  // Q&A
  qaSessions: ({ eventUri }) => `events/${eventUri}/event_qa_sessions`,
  qaSessionQuestions: ({ eventUri, sessionId, attendeeTypeId }) => `events/${eventUri}/event_qa_sessions/${sessionId}/event_qa_session_questions?include=event_qa_session,attendee,attendee.attendee_type_custom_fields&status=approved,answered&attendee_type_id=${attendeeTypeId}`,
  askQaQuestion: ({ eventUri, sessionId }) => `events/${eventUri}/event_qa_sessions/${sessionId}/event_qa_session_questions`,
  toggleQaQuestionLike: ({ eventUri, sessionId, id }) => `events/${eventUri}/event_qa_sessions/${sessionId}/event_qa_session_questions/${id}`,
  // Firebase
  firebaseToken: ({ eventUri }) => `events/${eventUri}/firebase/token`,
  // random stuff
  twilioToken: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/twilio/token`,
  dailyToken: ({ eventUri, workshopId }) => `events/${eventUri}/workshops/${workshopId}/daily-token`,
  activityICS: ({ eventUri, businessConferenceId, activityId }) => `events/${eventUri}/${businessConferenceId ? `business_conferences/${businessConferenceId}/business_conference_meetings/` : 'workshops/'}${activityId}/ics`,
  tags: ({ eventUri }) => `events/${eventUri}/tags`,
  demodeskToken: ({ eventUri, businessConferenceId, meetingId }) => `events/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings/${meetingId}/demodesk/token`,
  registrationToken: ({ eventUri }) => `events/${eventUri}/attendees/register-token`,
};

const appendFilters = (endpoint, filters) => {
  if (!filters || Object.keys(filters).length === 0) return endpoint;

  const connector = endpoint.includes('?') ? '&' : '?';
  const query = Object.keys(filters)
    .filter((f) => (typeof filters[f] !== 'undefined' && filters[f] !== null && filters[f] !== ''))
    .map((filter) => `${encodeURIComponent(humps.decamelize(filter))}=${encodeURIComponent(filters[filter])}`)
    .join('&');
  return `${endpoint}${connector}${query}`;
};

// const [defaultHost, defaultSocket, defaultEnv] = ['http://localhost:3002', 'wss://dev-iridium.eventtia.com/socket', 'local'];
const [defaultHost, defaultSocket, defaultEnv] = ['https://connect.eventtia.com', 'wss://iridium.eventtia.com/socket', 'prod'];
// const [defaultHost, defaultSocket, defaultEnv] = ['https://dev-fg.eventtia.com', 'wss://dev-iridium.eventtia.com/socket', 'dev'];
// const [defaultHost, defaultSocket, defaultEnv] = ['https://stage-fg.eventtia.com', 'wss://stage-iridium.eventtia.com/socket', 'stage'];

const host = process.env.REACT_APP_EVENTTIA_HOST || defaultHost;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || defaultSocket;
export const DEFAULT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || defaultEnv;

export const getZoomMeetingUrl = (eventUri, guid, name, email) => `${host}/${getLocale()}/public/events/${eventUri}/workshops/${guid}/zoom?name=${name}&email=${email}&ui_lang=${getLocale({ bypassLocaleMapping: true })}`;

export const getPublicRegistrationUrl = (eventUri, uuid = '') => `${host}/${getLocale()}/public/events/${eventUri}/registration/attendees${uuid ? `/${uuid}` : ''}`;
export const getUpdateRegistrationUrl = (eventUri, uuid, token) => `${host}/${getLocale()}/public/events/${eventUri}/registration/attendees/${uuid}?token=${token}`;
const twilioHost = process.env.REACT_APP_TWILIO_HOST || 'http://localhost:3001';
// const twilioHost = process.env.REACT_APP_TWILIO_HOST || 'https://dev-hello.eventtia.com';
export const getTwilioUrl = (eventUri, conference, guid, token) => `${twilioHost}/room/${eventUri}/${conference}/${guid}?token=${token}&locale=${getLocale()}`;
export const getTwilioPreviewUrl = () => `${twilioHost}/room/eventtia/video/test?previewOnly=true`;

const vonageHost = process.env.REACT_APP_VONAGE_HOST || 'http://localhost:3001';
export const getVonageUrl = (eventUri, conferenceId, meetingId, token) => `${vonageHost}/en/${eventUri}/conference/${conferenceId}/meeting/${meetingId}?token=${token}`;
export const getVonagePreviewUrl = () => `${vonageHost}/en/eventtia/video/test?previewOnly=true`;

export const getSamlSSOUrl = (loginUri, ssoStandardType, eventUri) => `${host}/users/auth/${ssoStandardType}?locale=${getLocale()}&login_uri=${loginUri}&provider=saml&event_uri=${eventUri}`;
export const getOpenIdSSOUrl = (provider, loginUri, eventUri) => `${host}/${getLocale()}/users/auth/openid/${provider}/setup?login_uri=${loginUri}&event_uri=${eventUri}`;

export default (name, params, filters) => {
  const locale = getLocale();
  const endpoint = typeof endpoints[name] === 'function' ? endpoints[name](params) : endpoints[name];
  const endpointWithFilters = appendFilters(endpoint, filters);
  return `${host}/${locale}/api/v3/${endpointWithFilters}`;
};
