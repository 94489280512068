import { SvgIcon } from '@material-ui/core';
import React from 'react';

const AbeilleIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Calque_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 1200 1200"
    >
      <defs id="defs340">

        <linearGradient
          id="Dégradé_sans_nom_7_-_copie"
          data-name="Dégradé sans nom 7 - copie"
          x1="104.78"
          y1="566.93"
          x2="1029.08"
          y2="566.93"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stopColor="#d4a43d"
            id="stop325"
          />
          <stop
            offset=".03"
            stopColor="#cc9a3b"
            id="stop327"
          />
          <stop
            offset=".11"
            stopColor="#bd8839"
            id="stop329"
          />
          <stop
            offset=".19"
            stopColor="#b47c38"
            id="stop331"
          />
          <stop
            offset=".27"
            stopColor="#b17938"
            id="stop333"
          />
          <stop
            offset=".67"
            stopColor="#d3a75e"
            id="stop335"
          />
          <stop
            offset=".97"
            stopColor="#f7e288"
            id="stop337"
          />
        </linearGradient>
      </defs>
      <path
        className="st2"
        d="M3727.11,43.09"
        id="path342"
      />
      <g id="g392">
        <g id="g366">
          <path
            className="st1"
            d="M1027.61,525.03c-9.53-65.05-71.68-105.44-131.39-119.39-57.09-13.56-136.88-28.23-210.47-30.61-7.13-.24-29.63-.13-39.59.02-2.16.04-5.12-.04-6.04.74-.61.48-1.22,1.29-.2,2.35,1.44,1.5,3.68,3.01,6.08,4.4,4.47,2.62,24.66,11.05,31.37,14.04,47.04,20.9,91.73,46.8,135.27,74.18,8.35,4.75,16.42,9.9,24.31,15.32,23.67,16.22,45.78,34.71,69.26,51.27,3.09,2.16,6.19,4.3,9.33,6.41,11.49,7.7,23.63,14.93,37.15,17.88,22.06,4.84,46.24-1.92,66.97-9.39,2.27-.81,6.21-1.66,7.94-3.4.78-.76,1.18-1.74,1.42-2.81.28-1.27-.67-15.35-1.42-21.01"
            id="path344"
          />
          <path
            className="st1"
            d="M720.82,151.16c11.2,32.2,12.58,65.07-.35,97.03-9.7,24-25.35,45.02-42.59,64.07-3.4,3.73-6.39,7.52-7.08,12.69-1.24,9.15,5.34,19.49,14.34,22.23,11.86,3.58,20.4-8.41,26.35-16.7,14.11-19.6,27.81-39.76,37.45-61.98,19.73-45.39,10-97.7-10.86-140.82-7.85-16.22-17.29-35.62-30.47-48.24-4.08-3.77-9.18-6.5-14.54-2.99-6.85,4.49-2.42,14.91.61,20.38,9.85,17.77,20.45,35.01,27.14,54.34"
            id="path346"
          />
          <path
            className="st1"
            d="M796.11,521.69c-48.39-39.28-100.73-73.4-156-102.17-1.92-1-4.62-1.83-5.91-.11-1.03,1.39-.26,3.34.55,4.88,28.58,54.43,54.54,111.5,72.24,170.53,21.04,62.87,62.59,210.45,66.77,219.78,10.42,22.11,30.83,38.74,50.84,51.95,17.83,11.77,37.17,21.06,57.14,28.51,3.03,1.15,24.39,8.22,32.11,10.38,5.88,1.63,11.42,2.09,16.02-2.64,33.83-33.9,55.63-82.6,52.82-130.91-2.25-56.46-35.55-104.77-73.24-144.46-35.86-37.74-73.35-73.25-113.34-105.73"
            id="path348"
          />
          <path
            className="st1"
            d="M181.18,561.64c13.51-2.96,25.66-10.2,37.17-17.9,3.14-2.09,6.24-4.23,9.33-6.39,23.46-16.57,45.56-35.07,69.24-51.29,7.89-5.41,15.96-10.55,24.31-15.32,42.53-26.71,86.15-52.06,131.99-72.7,7.78-3.51,30.19-12.9,34.68-15.5,2.36-1.4,4.62-2.92,6.06-4.42,1-1.03.39-1.85-.2-2.35-.94-.78-3.88-.7-6.02-.74-9.98-.13-32.48-.24-39.63-.02-73.57,2.38-153.38,17.05-210.45,30.63-59.71,13.95-121.88,54.32-131.41,119.39-.74,5.63-1.68,19.73-1.42,20.99.24,1.09.65,2.07,1.42,2.83,1.76,1.72,5.69,2.57,7.94,3.38,20.73,7.48,44.95,14.24,66.99,9.4"
            id="path350"
          />
          <path
            className="st1"
            d="M462.26,51.33c13.47,6.21,25.42,12.77,35.92,23.48,6.85,7,12.71,14.96,17.75,23.35,2.57,4.27,4.93,8.63,7.13,13.08,1.61,3.25,3.05,8.79,5.8,11.47-26.75,37.21-47.09,80.59-54.43,125.74-7.54,62,31,101.71,92.5,102.07,61.49-.37,100.02-40.07,92.5-102.07-7.33-45.15-27.69-88.53-54.45-125.74,2.75-2.68,4.19-8.22,5.8-11.47,2.2-4.45,4.56-8.81,7.13-13.08,5.04-8.39,10.9-16.35,17.77-23.35,10.48-10.72,22.45-17.27,35.9-23.48,5.8-2.7,11.97-8.78,8.2-15.59-2.73-4.93-9.57-6.12-14.65-4.82-18.25,4.58-34.53,15.48-47.98,28.95-7.32,7.28-14.36,15.32-19.92,24.17-3.45,5.47-6.76,11.42-8.42,17.74-6.02-7.17-12.95-13.97-21.89-14.96-8.94,1-15.89,7.8-21.89,14.96-1.68-6.32-4.97-12.27-8.44-17.74-5.54-8.85-12.6-16.89-19.9-24.17-13.45-13.47-29.74-24.37-48-28.95-5.06-1.29-11.92-.11-14.63,4.82-3.77,6.82,2.4,12.9,8.18,15.59"
            id="path352"
          />
          <path
            className="st1"
            d="M658.5,643.33c-10.25-10.07-25.44-12.01-39.19-11.84-17.46.22-34.9,2.07-52.38,2.22-17.48-.15-34.92-2-52.4-2.22-13.73-.17-28.91,1.77-39.17,11.84-8.96,8.81-12.03,21.8-15.63,33.46-6.5,21.89-.72,39.65,22.72,45.86,26.96,7.72,56.59,8.15,84.47,8.7,27.88-.55,57.49-.98,84.45-8.7,23.44-6.21,29.23-23.96,22.72-45.86-3.58-11.66-6.67-24.65-15.61-33.46"
            id="path354"
          />
          <path
            className="st1"
            d="M656.55,892.35c-29.95-3.14-59.69,2.51-89.62,2.05-29.93.46-59.67-5.19-89.6-2.05-18.25,1.9-38.65,7.35-48.64,24.24-15.8,26.66.24,59.62,14.19,83.3,15.06,25.57,34.57,48.96,57,68.38,18.7,16.2,41.75,32.39,67.06,35.16,25.31-2.77,48.39-18.96,67.06-35.16,22.43-19.42,41.94-42.81,57-68.38,13.95-23.69,30-56.64,14.21-83.3-10-16.89-30.39-22.34-48.64-24.24"
            id="path356"
          />
          <path
            className="st1"
            d="M566.94,754.16c-23.54,1.02-65.11-5.82-97.14,2.49-10.36,2.68-18.11,8.15-23.69,17.39-5.28,8.76-8.11,18.73-10.94,28.47-7.96,27.36,3.18,45.78,30.5,52.99,32.39,8.92,67.8,10.01,101.26,10.62,33.44-.61,68.86-1.7,101.24-10.62,27.32-7.21,38.47-25.62,30.52-52.99-2.83-9.74-5.67-19.71-10.96-28.47-5.58-9.24-13.32-14.71-23.67-17.39-32.04-8.31-73.62-1.48-97.14-2.49"
            id="path358"
          />
          <path
            className="st1"
            d="M566.94,379.48c-29.89,7.83-56.2,65.73-68.14,92.76-31.3,71.46-24.28,138.01,68.14,138.58,92.39-.57,99.41-67.12,68.14-138.58-11.93-27.03-38.26-84.93-68.14-92.76"
            id="path360"
          />
          <path
            className="st1"
            d="M422.37,330.48c5.97,8.3,14.5,20.29,26.35,16.7,9-2.73,15.59-13.08,14.36-22.23-.7-5.17-3.7-8.96-7.09-12.69-17.22-19.05-32.89-40.07-42.57-64.07-12.95-31.96-11.57-64.83-.37-97.03,6.69-19.32,17.31-36.56,27.16-54.34,3.01-5.47,7.45-15.89.59-20.38-5.34-3.51-10.46-.78-14.54,2.99-13.15,12.62-22.61,32.02-30.43,48.22-20.86,43.14-30.61,95.44-10.9,140.84,9.66,22.23,23.35,42.36,37.45,61.98"
            id="path362"
          />
          <path
            className="st1"
            d="M499.12,424.28c.81-1.53,1.59-3.47.55-4.88-1.29-1.7-3.99-.89-5.89.11-55.28,28.78-107.64,62.89-156.02,102.17-40,32.48-77.5,67.99-113.34,105.73-37.67,39.7-70.96,88.02-73.22,144.46-2.83,48.33,18.96,97.01,52.78,130.91,4.6,4.73,10.14,4.29,16.04,2.64,7.72-2.14,29.08-9.24,32.11-10.38,19.99-7.45,39.33-16.74,57.14-28.51,20.01-13.19,40.41-29.84,50.84-51.95,4.16-9.33,45.73-156.89,66.77-219.78,17.72-59.03,43.64-116.1,72.24-170.53"
            id="path364"
          />
        </g>
        <g id="g390">
          <path
            className="st0"
            d="M1027.61,525.03c-9.53-65.05-71.68-105.44-131.39-119.39-57.09-13.56-136.88-28.23-210.47-30.61-7.13-.24-29.63-.13-39.59.02-2.16.04-5.12-.04-6.04.74-.61.48-1.22,1.29-.2,2.35,1.44,1.5,3.68,3.01,6.08,4.4,4.47,2.62,24.66,11.05,31.37,14.04,47.04,20.9,91.73,46.8,135.27,74.18,8.35,4.75,16.42,9.9,24.31,15.32,23.67,16.22,45.78,34.71,69.26,51.27,3.09,2.16,6.19,4.3,9.33,6.41,11.49,7.7,23.63,14.93,37.15,17.88,22.06,4.84,46.24-1.92,66.97-9.39,2.27-.81,6.21-1.66,7.94-3.4.78-.76,1.18-1.74,1.42-2.81.28-1.27-.67-15.35-1.42-21.01"
            id="path368"
          />
          <path
            className="st0"
            d="M720.82,151.16c11.2,32.2,12.58,65.07-.35,97.03-9.7,24-25.35,45.02-42.59,64.07-3.4,3.73-6.39,7.52-7.08,12.69-1.24,9.15,5.34,19.49,14.34,22.23,11.86,3.58,20.4-8.41,26.35-16.7,14.11-19.6,27.81-39.76,37.45-61.98,19.73-45.39,10-97.7-10.86-140.82-7.85-16.22-17.29-35.62-30.47-48.24-4.08-3.77-9.18-6.5-14.54-2.99-6.85,4.49-2.42,14.91.61,20.38,9.85,17.77,20.45,35.01,27.14,54.34"
            id="path370"
          />
          <path
            className="st0"
            d="M796.11,521.69c-48.39-39.28-100.73-73.4-156-102.17-1.92-1-4.62-1.83-5.91-.11-1.03,1.39-.26,3.34.55,4.88,28.58,54.43,54.54,111.5,72.24,170.53,21.04,62.87,62.59,210.45,66.77,219.78,10.42,22.11,30.83,38.74,50.84,51.95,17.83,11.77,37.17,21.06,57.14,28.51,3.03,1.15,24.39,8.22,32.11,10.38,5.88,1.63,11.42,2.09,16.02-2.64,33.83-33.9,55.63-82.6,52.82-130.91-2.25-56.46-35.55-104.77-73.24-144.46-35.86-37.74-73.35-73.25-113.34-105.73"
            id="path372"
          />
          <path
            className="st0"
            d="M181.18,561.64c13.51-2.96,25.66-10.2,37.17-17.9,3.14-2.09,6.24-4.23,9.33-6.39,23.46-16.57,45.56-35.07,69.24-51.29,7.89-5.41,15.96-10.55,24.31-15.32,42.53-26.71,86.15-52.06,131.99-72.7,7.78-3.51,30.19-12.9,34.68-15.5,2.36-1.4,4.62-2.92,6.06-4.42,1-1.03.39-1.85-.2-2.35-.94-.78-3.88-.7-6.02-.74-9.98-.13-32.48-.24-39.63-.02-73.57,2.38-153.38,17.05-210.45,30.63-59.71,13.95-121.88,54.32-131.41,119.39-.74,5.63-1.68,19.73-1.42,20.99.24,1.09.65,2.07,1.42,2.83,1.76,1.72,5.69,2.57,7.94,3.38,20.73,7.48,44.95,14.24,66.99,9.4"
            id="path374"
          />
          <path
            className="st0"
            d="M462.26,51.33c13.47,6.21,25.42,12.77,35.92,23.48,6.85,7,12.71,14.96,17.75,23.35,2.57,4.27,4.93,8.63,7.13,13.08,1.61,3.25,3.05,8.79,5.8,11.47-26.75,37.21-47.09,80.59-54.43,125.74-7.54,62,31,101.71,92.5,102.07,61.49-.37,100.02-40.07,92.5-102.07-7.33-45.15-27.69-88.53-54.45-125.74,2.75-2.68,4.19-8.22,5.8-11.47,2.2-4.45,4.56-8.81,7.13-13.08,5.04-8.39,10.9-16.35,17.77-23.35,10.48-10.72,22.45-17.27,35.9-23.48,5.8-2.7,11.97-8.78,8.2-15.59-2.73-4.93-9.57-6.12-14.65-4.82-18.25,4.58-34.53,15.48-47.98,28.95-7.32,7.28-14.36,15.32-19.92,24.17-3.45,5.47-6.76,11.42-8.42,17.74-6.02-7.17-12.95-13.97-21.89-14.96-8.94,1-15.89,7.8-21.89,14.96-1.68-6.32-4.97-12.27-8.44-17.74-5.54-8.85-12.6-16.89-19.9-24.17-13.45-13.47-29.74-24.37-48-28.95-5.06-1.29-11.92-.11-14.63,4.82-3.77,6.82,2.4,12.9,8.18,15.59"
            id="path376"
          />
          <path
            className="st0"
            d="M658.5,643.33c-10.25-10.07-25.44-12.01-39.19-11.84-17.46.22-34.9,2.07-52.38,2.22-17.48-.15-34.92-2-52.4-2.22-13.73-.17-28.91,1.77-39.17,11.84-8.96,8.81-12.03,21.8-15.63,33.46-6.5,21.89-.72,39.65,22.72,45.86,26.96,7.72,56.59,8.15,84.47,8.7,27.88-.55,57.49-.98,84.45-8.7,23.44-6.21,29.23-23.96,22.72-45.86-3.58-11.66-6.67-24.65-15.61-33.46"
            id="path378"
          />
          <path
            className="st0"
            d="M656.55,892.35c-29.95-3.14-59.69,2.51-89.62,2.05-29.93.46-59.67-5.19-89.6-2.05-18.25,1.9-38.65,7.35-48.64,24.24-15.8,26.66.24,59.62,14.19,83.3,15.06,25.57,34.57,48.96,57,68.38,18.7,16.2,41.75,32.39,67.06,35.16,25.31-2.77,48.39-18.96,67.06-35.16,22.43-19.42,41.94-42.81,57-68.38,13.95-23.69,30-56.64,14.21-83.3-10-16.89-30.39-22.34-48.64-24.24"
            id="path380"
          />
          <path
            className="st0"
            d="M566.94,754.16c-23.54,1.02-65.11-5.82-97.14,2.49-10.36,2.68-18.11,8.15-23.69,17.39-5.28,8.76-8.11,18.73-10.94,28.47-7.96,27.36,3.18,45.78,30.5,52.99,32.39,8.92,67.8,10.01,101.26,10.62,33.44-.61,68.86-1.7,101.24-10.62,27.32-7.21,38.47-25.62,30.52-52.99-2.83-9.74-5.67-19.71-10.96-28.47-5.58-9.24-13.32-14.71-23.67-17.39-32.04-8.31-73.62-1.48-97.14-2.49"
            id="path382"
          />
          <path
            className="st0"
            d="M566.94,379.48c-29.89,7.83-56.2,65.73-68.14,92.76-31.3,71.46-24.28,138.01,68.14,138.58,92.39-.57,99.41-67.12,68.14-138.58-11.93-27.03-38.26-84.93-68.14-92.76"
            id="path384"
          />
          <path
            className="st0"
            d="M422.37,330.48c5.97,8.3,14.5,20.29,26.35,16.7,9-2.73,15.59-13.08,14.36-22.23-.7-5.17-3.7-8.96-7.09-12.69-17.22-19.05-32.89-40.07-42.57-64.07-12.95-31.96-11.57-64.83-.37-97.03,6.69-19.32,17.31-36.56,27.16-54.34,3.01-5.47,7.45-15.89.59-20.38-5.34-3.51-10.46-.78-14.54,2.99-13.15,12.62-22.61,32.02-30.43,48.22-20.86,43.14-30.61,95.44-10.9,140.84,9.66,22.23,23.35,42.36,37.45,61.98"
            id="path386"
          />
          <path
            className="st0"
            d="M499.12,424.28c.81-1.53,1.59-3.47.55-4.88-1.29-1.7-3.99-.89-5.89.11-55.28,28.78-107.64,62.89-156.02,102.17-40,32.48-77.5,67.99-113.34,105.73-37.67,39.7-70.96,88.02-73.22,144.46-2.83,48.33,18.96,97.01,52.78,130.91,4.6,4.73,10.14,4.29,16.04,2.64,7.72-2.14,29.08-9.24,32.11-10.38,19.99-7.45,39.33-16.74,57.14-28.51,20.01-13.19,40.41-29.84,50.84-51.95,4.16-9.33,45.73-156.89,66.77-219.78,17.72-59.03,43.64-116.1,72.24-170.53"
            id="path388"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default AbeilleIcon;
