import { useParams } from 'react-router-dom';

const hackList = {
  isScheduleDefaultList: ['2025-hillrobinson-company-seminar', 'digital1to1-winter-2023', 'tince23', 'digital1to1-italy-2023', 'digital1to1-travel-2023', 'retailluxurymbaforum', 'digital1to1-spring-2024', 'alpuraconave2024', 'convencion2024', 'abril24', 'juin2024', 'digital1to1-summer-2024', 'junio24', 'auumm2024', 'fpa2024', 'agosto24', 'digital1to1-italy-2024', 'digital1to1-winter-2024', 'digital-connection-2024', 'salonmonetiqueregional', 'noviembre24', 'digital1to1-travel-2024', 'febrero25', 'digital1to1-spring-spain-2025', 'worldwide-meeting-2025', 'ipadetf2025', 'abril25'],
  changeDefaultLiveNowIcon: ['tince23'],
  changeLiveNowDefaultTitle: ['tince23'],
  sortAttendeesByLastName: ['tince23'],
  skipAnalyticsTrackings: ['voannualnetworkmeeting2022', 'netwirkig'],
  hackedForOnClickActivityCard: ['investorsday', 'surainvestmentforum', '11forodeemisoras'],
  isPureBlackActivityCard: ['ss23', 'netwirkig', '22pro5', 'demo-juanortega'],
  loggedOutBgLayout: ['cbg'],
  hideStageSidebar: ['netwirkig', 'pernodricardsaconference', 'journees-naturalite', 'worldwide-meeting-2025', 'dantznetworkgcongress2025'],
  hideLiveNow: ['e-glm-2022', '22pro5', 'tdc-conference-nlc'],
  hideTabDailyStreaming: ['elearning-africa-primary-secondary-education', 'ettc2021', 'languages_and_the_media'],
  hideMainStage: ['50b1', 'jli-global-essay-prize-2024', 'vibe2025', 'df25', 'boostyournetwork2025'],
  hideAttendeeTypesFilter: ['assisesausim2024'],
  hideWaitingMessage: ['gifas-delegation-to-india'],
  changeOrderSponsorsByAmount: ['gifas-delegation-to-india'],
  changeSpeakersSubtitle: ['worldwide-meeting-2025'],
  hideBiographyText: ['worldwide-meeting-2025'],
  termsAndConditions: ['worldwide-meeting-2025'],
};

const configs = {
  limitLocaleDropdownOptions:
    {
      '2022edition': ['en', 'tr'],
      networkingeventtia: ['en', 'es'],
      'demo-juanortega': ['en', 'es'],
    },
  changeSpeakersSubtitle:
    {
      'worldwide-meeting-2025': 'If you require any assistance, please do not hesitate to contact the WWM team.',
    },
  termsAndConditions: {
    'worldwide-meeting-2025': {
      termsFieldInternalId: 'terms',
      agreementText: 'By attending the 2025 Worldwide Meeting, you agree to keep all information shared during this event strictly confidential and not to disclose, use, copy, reproduce, or record any part of it, as specified in the General Terms of Use of the present platform.',
      textToLink: 'General Terms of Use',
    },
  },
};

const useHack = (hackName) => {
  const { eventUri } = useParams();
  return hackList[hackName]?.includes(eventUri);
};

export const useEventUriHackConfig = (hackName) => {
  const { eventUri } = useParams();
  const hackConfig = configs[hackName];

  return hackConfig?.[eventUri];
};

export default useHack;
