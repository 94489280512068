import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { baseMoment } from '../../helpers/dates';
import ScheduleListItem from '../ScheduleListItem';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import ScheduleNoActivities from '../ScheduleNoActivities';

const ScheduleList = ({
  activities,
  activeType,
  keyword,
  filters,
  events,
  day,
}) => {
  const { endDate: eventEndDate } = Object.values(events)[0];

  let filteredActivities = day ? activities[day] : Object.values(activities).flat();

  if (keyword) filteredActivities = filteredActivities.filter(
    ({ name }) => name?.toLowerCase().includes(keyword.toLowerCase())
  ).sort(
    // why???
    (a, b) => a.name.trim().localeCompare(b.name.trim())
  );

  if (filters.dateFrom) filteredActivities = filteredActivities.filter(
    // why???
    ({ startDate }) => baseMoment(startDate).isBetween(filters.dateFrom, eventEndDate, 'minute', '[)')
  );

  if (filters.categories?.length) filteredActivities = filteredActivities.filter(
    (activity) => activity.workshopCategories?.some(
      (category) => filters.categories.includes(category.id)
    )
  );

  if (filters.speakers?.length) filteredActivities = filteredActivities.filter(
    (activity) => activity.speakers?.some(
      (speaker) => filters.speakers.includes(speaker.id)
    )
  );

  const orderedActivities = filteredActivities.sort((a, b) => {
    // why hour????
    if (baseMoment(a.startDate).isAfter(b.startDate, 'hour')) return 1;
    if (baseMoment(a.startDate).isBefore(b.startDate, 'hour')) return -1;
    return 0;
  });

  return (
    orderedActivities.length ? (
      orderedActivities.map((activity) => (
        <ScheduleListItem activity={activity} key={activity.id} />
      ))
    ) : (
      <ScheduleNoActivities
        activeType={activeType}
      />
    )
  );
};

ScheduleList.propTypes = {
  activities: PropTypes.objectOf(
    PropTypes.arrayOf(
      CustomPropTypes.workshop
    )
  ),
  activeType: PropTypes.string.isRequired,
  keyword: PropTypes.string,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.string
    ),
    speakers: PropTypes.arrayOf(
      PropTypes.string
    ),
  }),
  events: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  day: PropTypes.string,
};

ScheduleList.defaultProps = {
  activities: {},
  keyword: undefined,
  filters: {},
  day: '',
};

const mapStateToProps = ({ entities: { events } }) => ({ events });

export default connect(mapStateToProps)(ScheduleList);
