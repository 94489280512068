import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SearchInput from 'eventtia-ui-components/lib/SearchInput';
import { getModuleNames } from '../../helpers/getters';
import PersonCard from '../../components/PersonCard';
import TabletExitButton from '../../components/TabletExitButton';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import SubpageTitle from '../../components/SubpageTitle';
import { setActiveSpeaker } from '../../actions/app';
import Biography from '../Biography';
import useEventUriHack, { useEventUriHackConfig } from '../../hooks/hacks/useEventUriHack';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'hidden',
    margin: 0,
    padding: 0,
  },
  search: {
    margin: theme.spacing(3, 0, 1.5, 0),
    display: 'flex',
    minHeight: theme.spacing(6),
    justifyContent: 'flex-end',
  },
  biographyContainer: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 6.5),
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  biographyContainerTablet: {
    width: '100%',
  },
  biographyContainerMobile: {
    padding: theme.spacing(4),
  },
  scroll: {
    minWidth: 320,
    padding: '28px 52px 20px 52px',
    maxHeight: '100%',
    overflow: 'auto',
  },
  mobileScroll: {
    padding: '28px 21px 20px 21px',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
}));

const Speakers = ({
  order, speakers, appSettings, pickedSpeaker,
  setActiveSpeaker: dispatchSetActiveSpeaker,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['speakers', 'global']);
  const [keyword, setKeyword] = useState();
  const searchRef = useRef(null);
  const moduleTitle = getModuleNames(appSettings, 'Speakers');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const changeCopy = useEventUriHack('changeSpeakersSubtitle');
  const hackedCopy = useEventUriHackConfig('changeSpeakersSubtitle');

  const search = () => {
    setKeyword(searchRef.current?.value);
  };

  const orderSpeakers = keyword
    ? Object.values(speakers)
      .filter(({ fullName, company }) => fullName.toLowerCase().includes(keyword.toLowerCase())
      || company.toLowerCase().includes(keyword.toLowerCase()))
      .sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()))
    : (order || []).map((id) => speakers[id]);

  const clearSearch = () => {
    searchRef.current.value = '';
    searchRef.current.blur();
    setKeyword();
  };

  return (
    <>
      {!pickedSpeaker && (
        <TabletExitButton />
      )}
      {!!pickedSpeaker && (
        <Container className={clsx(classes.biographyContainer, tablet
          && classes.biographyContainerTablet, mobile && classes.biographyContainerMobile)}
        >
          <Biography
            speaker={pickedSpeaker}
          />
        </Container>
      )}
      <Container className={classes.container}>
        <div className={mobile ? classes.mobileScroll : classes.scroll}>
          <SubpageTitle text={moduleTitle} />
          <Typography className={classes.moduleDescription} variant="body1">
            {
              !changeCopy ? t('title.description') : hackedCopy
            }
          </Typography>
          <div className={classes.search}>
            <SearchInput
              placeholder={t('global:forms.searchKeyword')}
              inputRef={searchRef}
              onSubmit={search}
              onClear={clearSearch}
              className={mobile ? classes.mobileSearchInput : undefined}
              fullWidth={false}
              margin="dense"
            />
          </div>
          <Grid container spacing={2}>
            {orderSpeakers.map((speaker) => (
              <Grid item xs={12} sm={12} md={4} lg={3} key={speaker.id}>
                <PersonCard
                  person={speaker}
                  onBlockClick={dispatchSetActiveSpeaker}
                  type="speaker"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </>
  );
};

Speakers.propTypes = {
  order: PropTypes.arrayOf(PropTypes.string).isRequired,
  speakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
  pickedSpeaker: CustomPropTypes.speaker,
  appSettings: CustomPropTypes.appSettings.isRequired,
  setActiveSpeaker: PropTypes.func.isRequired,
};

Speakers.defaultProps = {
  speakers: {},
  pickedSpeaker: undefined,
};

const mapStateToProps = ({
  entities: {
    speakers,
    appSettings,
  },
  fetchStatus: {
    speakers: {
      order,
    },
  },
  app: {
    activeSubpage: {
      speakerId,
    },
  },
}) => ({
  speakers,
  appSettings,
  order,
  pickedSpeaker: speakers?.[speakerId],
});

export default connect(mapStateToProps, { setActiveSpeaker })(Speakers);
