import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'eventtia-ui-components/lib/Modal';
import QrCode from 'qrcode.react';
import ResponsiveButton from '../ResponsiveButton';
import QrCodeIcon from '../../assets/QrCodeIcon';
import { buildQrContent } from '../../helpers/attendeeLeads';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 430,
    maxWidth: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: theme.palette.lightGrey.light,
    padding: theme.spacing(8, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  message: {
    color: theme.palette.darkGrey.main,
    margin: theme.spacing(1, 0),
  },
  qrContainer: {
    display: 'inline-block',
    borderRadius: 8,
    padding: theme.spacing(4),
    margin: theme.spacing(2, 0),
    background: 'white',
  },
}));

const MyQrButton = ({ attendeeInfo, showLeadsOptions }) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const {
    uuid, firstName, lastName, email, company, jobTitle, telephone,
  } = attendeeInfo;
  const qrContent = buildQrContent(uuid, firstName, lastName, email, company, jobTitle, telephone);

  return (
    <>
      <ResponsiveButton
        onClick={openModal}
        icon={<QrCodeIcon />}
        label={t('actions.showQr')}
      />
      <Modal
        open={open}
        handleClose={closeModal}
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <Paper
          className={classes.dialog}
        >
          <IconButton
            className={classes.closeButton}
            aria-label={t('actions.close')}
            onClick={closeModal}
            edge="end"
            color="primary"
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography className={classes.message}>
            {t('attendeeLeads:qr.personalQr')}
          </Typography>
          <div className={classes.qrContainer}>
            <QrCode value={qrContent} />
          </div>
          {
            !!showLeadsOptions
            && (
              <Typography className={classes.message}>
                  {t('attendeeLeads:qr.description')}
              </Typography>
            )
          }
        </Paper>
      </Modal>
    </>
  );
};

MyQrButton.propTypes = {
  attendeeInfo: PropTypes.shape({
    uuid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    telephone: PropTypes.string,
  }).isRequired,
  showLeadsOptions: PropTypes.bool.isRequired,
};

export default MyQrButton;
