import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import callApi, { fetchConfig } from '../../actions/callApi';
import { useEventUriHackConfig } from '../../hooks/hacks/useEventUriHack';
import { parseFieldsByInternalId } from '../../helpers/customFields';

const replaceText = (baseText, textToReplace, href) => baseText.replace(new RegExp(textToReplace, 'g'), `<a href="${href}" target="_blank" rel="noopener noreferrer">${textToReplace}</a>`);

const useStyles = makeStyles(() => ({
  dialogRoot: {
    zIndex: '99999 !important',
  },
}));

const TermsAndConditionsModal = ({
  currentAttendee, attendeeTypeCustomFields, callApi: dispatchCallApi, updatingTerms,
}) => {
  const { eventUri } = useParams();
  const classes = useStyles();

  const termsAndConditionsHackConfig = useEventUriHackConfig('termsAndConditions');
  const { termsFieldInternalId, agreementText, textToLink } = termsAndConditionsHackConfig || {};

  const fieldsId = parseFieldsByInternalId(currentAttendee?.fields, attendeeTypeCustomFields);
  const termsFieldId = fieldsId[termsFieldInternalId];
  const termsField = attendeeTypeCustomFields[termsFieldId];
  const termsUlr = termsField?.options?.url;
  const acceptedTerms = !!currentAttendee?.fields[termsFieldId];

  const onAccept = () => {
    const data = new FormData();
    data.append(`attendee[custom_fields][${termsFieldId}]`, true);
    dispatchCallApi('updateCurrentAttendee', { eventUri, id: currentAttendee.id, data })
      .then(({ type, error: errors }) => {
        const [, , updateFailure] = fetchConfig.updateCurrentAttendee.types;
        if (type === updateFailure) console.error(
          Array.isArray(errors) ? errors.join(', ') : errors
        );
      });
  };

  return (
    <Dialog
      open={!acceptedTerms}
      maxWidth="md"
      classes={{ root: classes.dialogRoot }}
    >
      <DialogTitle id="alert-dialog-title">Terms and Conditions of Use</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p dangerouslySetInnerHTML={
            { __html: replaceText(agreementText, textToLink, termsUlr) }
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={onAccept}
              disabled={updatingTerms}
              checked={acceptedTerms}
            />
          )}
          label="I accept the terms and conditions"
        />
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionsModal.propTypes = {
  callApi: PropTypes.func.isRequired,
  currentAttendee: CustomPropTypes.attendee.isRequired,
  updatingTerms: PropTypes.bool,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
};

TermsAndConditionsModal.defaultProps = {
  updatingTerms: false,
  attendeeTypeCustomFields: {},
};

const mapStateToProps = ({
  entities: {
    attendeeTypeCustomFields,
  },
  fetchStatus: {
    updateCurrentAttendee: { isFetching: updatingTerms },
  },
}) => ({
  updatingTerms,
  attendeeTypeCustomFields,
});

export default connect(mapStateToProps, { callApi })(TermsAndConditionsModal);
